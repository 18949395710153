import React, { useState } from "react";
import loginformStyle from "../LoginPage.module.css";
import axios from "axios";

function LoginForm() {
  const [contact, setContact] = useState({});

  const [errors, setErrors] = useState({});

  const [message, showMessage] = useState("");

  const handleChange = (keyName, keyValue) => {
    let update = { ...contact };
    update[keyName] = keyValue;
    setContact(update);
  };

  const handleSubmit = async () => {
    console.log("contact", contact);
    let check = validateContact(contact);
    setErrors({});
    if (check.isValid) {
      let data = JSON.stringify({
        name: `${contact.first_name} ${contact.last_name}`,
        email: `${contact.contact_email}`,
        phone: `${contact.contact_phone}`,
        message: `${contact.message}`,
      });
      let config = {
        method: "post",
        url: "https://api.venuemonk.com/utility/contactus-page",
        headers: {},
        data: data,
      };
      try {
        let res = await axios.request(config);
        if (res.data.status === "Success") {
          showMessage(
            "We have got your query, someone from our team will get back to you!"
          );
        }
        setErrors({});
          setContact({})
      } catch (e) {
        console.log(e);
        
      }
    } else {
      setErrors(check.errors);
    }
  };

  const validateContact = (contact) => {
    let errors = {};
    let isValid = true;
    if (contact) {
      if (!contact["first_name"]) {
        isValid = false;
        errors["first_name"] = "Please enter valid first name";
      }
      if (!contact["last_name"]) {
        isValid = false;
        errors["last_name"] = "Please enter valid last name";
      }
      if (!contact["contact_email"]) {
        isValid = false;
        errors["contact_email"] = "Please enter valid contact email";
      }
      if (!contact["contact_phone"]) {
        isValid = false;
        errors["contact_phone"] = "Please enter valid contact phone";
      }
      if (!contact["message"]) {
        isValid = false;
        errors["message"] = "Please enter valid message";
      }
    }
    return {
      isValid,
      errors,
    };
  };

  const showErrors = (keyName) => {
    return keyName && errors[keyName] ? (
      <>
        <br />
        <span style={{ fontSize: "12px", color: "red" }}>
          {errors[keyName]}
        </span>
      </>
    ) : null;
  };

  const showCustomTitle = (text) => {
    return text ? (
      <>
        <br />
        <div style={{marginBottom : "20px"}}>{text}</div>
      </>
    ) : null;
  };

  return (
    <div>
      <p className={loginformStyle["login-contact-us-heading"]}>Contact Us</p>
      {/* <div className={loginformStyle["Input-container"]}> */}
      {showCustomTitle(message)}
      <div className="row" style={{ marginBottom: "20px" }}>
        <div className="col-6">
          <input
          value={contact.first_name}
            onChange={(e) => handleChange("first_name", e.target.value)}
            placeholder="First Name"
            className={loginformStyle["login-input"]}
          />
          {showErrors("first_name")}
        </div>
        <div className="col-6">
          <input
          value={contact.last_name}
            onChange={(e) => handleChange("last_name", e.target.value)}
            placeholder="Last Name"
            className={loginformStyle["login-input"]}
          />
          {showErrors("last_name")}
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <input
          value={contact.contact_email}
            onChange={(e) => handleChange("contact_email", e.target.value)}
            placeholder="Email Address"
            className={loginformStyle["login-input"]}
          />
          {showErrors("contact_email")}
        </div>
        <div className="col-6">
          <input
          value={contact.contact_phone}
            onChange={(e) => handleChange("contact_phone", e.target.value)}
            placeholder="Phone Number"
            className={loginformStyle["login-input"]}
          />
          {showErrors("contact_phone")}
        </div>
        <div className="col-12">
          <textarea
          value={contact.message}
            onChange={(e) => handleChange("message", e.target.value)}
            placeholder={"Type Your message"}
            className={loginformStyle["loginmessage-container"]}
          />
          {showErrors("message")}
        </div>
      </div>

      {/* </div> */}
      <div className={loginformStyle["button-container"]}>
        <button
          onClick={() => handleSubmit()}
          className={loginformStyle["view-btn"]}
        >
          <span className={loginformStyle["View-button-text"]}>Submit</span>
        </button>
      </div>
    </div>
  );
}

export default LoginForm;
