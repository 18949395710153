import React, { useEffect, useState } from "react";
import loginformStyle from "./LoginPage.module.css";
import ContactDetails from "./component/ContactDetails";
import ContactForm from "./component/ContactForm";

function ContactUsPage() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <div className={loginformStyle["container"]}>
        <div className={loginformStyle["loginform"]}>
          {width > 768 ? (
            <>
              <div className={loginformStyle["login-form-details-container"]}>
                <ContactDetails />
              </div>
              <div className={loginformStyle["loginform-container"]}>
                <ContactForm />
              </div>
            </>
          ) : (
            <>
              <div className={loginformStyle["loginform-container"]}>
                <ContactForm />
              </div>
              <div className={loginformStyle["login-form-details-container"]}>
                <ContactDetails />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ContactUsPage;












