import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BlogDetailsPageStyles from "./BlogDetailsPage.module.css";
import { useParams } from "react-router-dom";
import author from "../../components/images/a-author.png";
import blogcontent from "../../constants/blog_content.json";
import { blogListPageURL, homepageURL } from "../../constants/path_constants";

function BlogDetailsPage() {
  let { url } = useParams();

  console.log("url", url);

  const [selectedBlog, setBlog] = useState({});

  useEffect(() => {
    if (url) {
      let blog = blogcontent.blogs.filter((item) => item.url === url);
      if (blog && blog[0]) {
        setBlog(blog[0]);
      }
    }
  }, [url]);

  return (
    <>
      <div className={BlogDetailsPageStyles["blog-detail-main-container"]}>
        <div className={BlogDetailsPageStyles["blogpage-one"]}></div>
        <div className={BlogDetailsPageStyles["indian-wedding-details"]}>
          <div>
            <h6 className={BlogDetailsPageStyles["homepage"]}>
              <a
                style={{ color: "black", textDecoration: "none" }}
                href={homepageURL}
              >
                Home
              </a>
              <span> {`>`} </span>
              <a
                style={{ color: "black", textDecoration: "none" }}
                href={blogListPageURL}
              >
                Blog
              </a>
              <span style={{ marginLeft: "5px" }}>{">"} </span>
              <span className={BlogDetailsPageStyles["blog-detail"]}>
                {selectedBlog?.title}
              </span>
            </h6>
            <div className={BlogDetailsPageStyles["card-deck3"]}>
              <div className={BlogDetailsPageStyles["card3"]}>
                <h2>{selectedBlog.title}</h2>
                <div className={BlogDetailsPageStyles["card-body"]}>
                  <div className={BlogDetailsPageStyles["shortimg-container"]}>
                    <img
                      className={BlogDetailsPageStyles["shortimg"]}
                      src={selectedBlog?.author_img}
                    />
                    <span>
                      {selectedBlog.author_name} {selectedBlog.published_date}
                    </span>
                  </div>
                </div>
                <div
                  className={BlogDetailsPageStyles["blog-text"]}
                  dangerouslySetInnerHTML={{ __html: selectedBlog.text }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogDetailsPage;
