export const testimonialcontent = [
    {
        src:`https://vmnk.gumlet.io/images/testimonial1.jpg`,
        name:'Akshita',
        rating:4,
        text:"I recently had the pleasure of hosting my daughter's wedding, and it was an absolutely enchanting experience working with you."
    },
    {
        src:`https://vmnk.gumlet.io/images/testimonial2.jpg`,
        name:'Suman',
        rating:5,
        text:"Our wedding day was nothing short of magical. From the moment we stepped onto the grounds, we knew it was the perfect place to exchange our vows and celebrate with loved ones."
    },
    {
        src:`https://vmnk.gumlet.io/images/testimonial-image-4.jpg`,
        name:'Deepshikha',
        rating:4,
        text:"We hosted our wedding and the venue was lovely. It was pretty huge and they also gave me a complimentary stay till the time I got ready for my special day and also, their services were great! "
    },
    {
        src:`https://vmnk.gumlet.io/images/testimonial-image-5.jpg`,
        name:'Gurpreet',
        rating:4,
        text:"Attended a wedding there twice and had great fun. The place for wedding functions and the decoration was very nice. The staff and food were also on time and the location of the banquet hall is great."
    },
    {
        src:"https://lh3.googleusercontent.com/a-/ALV-UjW_bq1uW9Xw1zB8V8iOL32CiL2FQATxPEljI5keNzuNXjVU=w60-h60-p-rp-mo-br100",
        name:'Archit',
        rating:4,
        text: "Finding weddingpe while planning my wedding was lucky! Their articles cover lots, from money to tech, helping couples navigate wedding prep. With useful tips and expert advice, weddingpe is a must for anyone getting married"
    },
    {
        src:`https://vmnk.gumlet.io/images/testimonial-image-3.jpg`,
        name:'Nitin',
        rating:4,
        text:"Planning a dream wedding often means facing daunting financial decisions. Fortunately, weddingpe offers valuable insights into managing wedding expenses."
    }
]