import React from "react";
import "./Checklist.css";
import Checklists_container from "../../../components/images/checklists_container.svg";
import checklistcontent from "../../../constants/checklist_content.json";

//import Elip from "../images/elip.svg";

function Checklist() {
  return (
    <div className="checklist-main-container">
      <div className="latestheading">
        <h2>Plan Easily</h2>
      </div>
      <div className="checklist-container">
        {/*First-container*/}
        <div className="checklist-container-one">
          <img src={Checklists_container} />
        </div>
        {/* Task-box
        </div>
          <div className="task-box">
            <div className="ellipse">
              <img className="ellipse-image1" src={Elip} />
            </div>
            {/*ellipse*/}
        {/*             
            <div className="ellipse-text">
              <h2>Your wedding checklist</h2>
              <p>Take a moment to relax and enjoy your special day!</p>
              <div className="greyline">
                <div id="rectangle"></div>
                <br />
                <div id="rectangle-2"></div>
              </div>
            </div> */}

        {/*month-div*/}
        {/* <div className="month">
            <h2 id="text">6 Months To Go</h2>
          </div> */}
        {/*close of month div*/}

        {/*To-do list*/}
        {/* <div className="to-do">
            <div className="item">
              <h5>
                75<p4>Done</p4>
              </h5>
              <h4>
                25<p4>To Do</p4>
              </h4>
            </div>
            <div className="greyline">
              <div id="rectangle"></div>
              <br />
              <div id="rectangle-2"></div>
            </div> 
          </div> */}
        {/*close of to-do div*/}

        {/*CheckList-box*/}
        {/* <div className="list-container" id="background-list-container">
            <label class="container">
              <span className="texts">Finalize Wedding Venue</span>
              <input type="checkbox" checked="checked" />
              <span class="checkmark"></span>
            </label>
            <label class="container">
              <span className="texts">Shortlist DJ</span>
              <input type="checkbox" />
              <span class="checkmark"></span>
            </label>
            <label class="container">
              <span className="texts">Buy Wedding Jewellery</span>
              <input type="checkbox" />
              <span class="checkmark"></span>
            </label>
          </div> */}

        {/*Second-container*/}
        {checklistcontent.checklists.map((checklist) => (
          <div className="checklist-container-two">
            <div className="line"></div>
            <div className="checklist-content">
              <div className="checklist-alignment">
                <h2>
                  <span style={{ color: "#FF477E" }}>Checklist</span> flawless
                  celebration
                </h2>
                <p>
                  {checklist.text_2}
                  <br />
                  {checklist.text_3}
                  <br />
                  {checklist.text_4}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Checklist;
