import React from "react";
import Blogstyle from "./BlogCard.module.css";

function BlogCard({ blogimage, title, authorimage, authortext, Badge }) {

  return (
    <div className={Blogstyle["blog-section-container"]}>
      <div className={Blogstyle["Card-Container"]}>
        <img
          className={Blogstyle["card-img-top"]}
          src={blogimage}
          alt="Card image cap"
        />
        <div className={Blogstyle["card-body"]}>
          <h5 className={Blogstyle["card-title"]}>
            <img src={Badge} />
          </h5>
          <p className={Blogstyle["card-text"]}>{title}</p>

          <div className={Blogstyle["shortimg-container-one"]}>
            <img className={Blogstyle["shortimg"]} src={authorimage} />
            <span>{authortext}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogCard;
