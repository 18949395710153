import { useState, useEffect } from "react";
import React from "react";
import "./Footer.css";
import logo from "../images/logo.png";
import { Footericon } from "../../constants/Constant";

function Footer() {
  const [pathName, setPathName] = useState(window.location.pathName);
  useEffect(() => {
    const pathName = window.location.pathname;
    setPathName(pathName);
  }, []);
  return (
    <>
      <footer
        className={
          pathName == "/Indian-Wedding"
            ? "footer_container_white"
            : "footer_container"
        }
      >
        <div className="firstlogo">
          <img src={logo} />
        </div>
        <div className="footer_bottom">
          <span className="footer_text">Copyright © 2024 WeddingPe</span>
          <span className="footer_text">
            Terms & Conditions | Privacy Policy
          </span>
          {/* <div>
            {Footericon.map(footer => (
            <a href={footer.link} style={{width:'25px',height:'25px'}}>
              <img src={footer.icon} alt="icon" />
            </a>
            ))}
            
          </div> */}
        </div>
      </footer>
    </>
  );
}

export default Footer;
