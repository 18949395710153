// src/components/LoanleadForm.js

import React, { useEffect, useState } from "react";
import loanPageStyle from "../loanPage/loan.module.css";
import OtpInput from "react-otp-input";
import { isNumeric } from "validator";
import {
  FORM_HEADER,
  MOBILE_VERIFICATION_TEXT,
  NEXT_TEXT,
  OPT_VERIFY_TEXT,
  SEND_WHATSAPP_TEXT,
  SUCCESSFUL_TEXT,
  VERIFY_OTP_SUBHEADING,
  VERIFY_TEXT,
} from "../../constants/Constant";
import { useDispatch, useSelector } from "react-redux";
import {
  createNewLead,
  isOtpScreenVisible,
  verifyOtp,
} from "../../Redux/Action/AppAction";
import {
  fetchLoanLeadDetails,
  getIsOtpFormVisible,
  getIsOtpVerify,
} from "../../Redux/Reducer/AppReducer";
import { showMessage } from "../../components/Toast/ToastAdd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../../App.css";
import { isEmpty } from "lodash";
import Loader from "../../components/loader/Loader";

function LoanleadForm({ user, event , isFetchingUser }) {
  const [contact, setContact] = useState({});
  const [otp, setOtp] = useState("");
  const [isToggled, setIsToggled] = useState(true);
  const dispatch = useDispatch();


  useEffect(() => {
    if (user && !isEmpty(user) && isEmpty(contact)) {
      let contact = {};
      contact.contact_name = user.username;
      contact.contact_number = `+91 ${user.phone}`;
      setContact(contact);
    }
  }, [user]);

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  const handleChange = (keyName, keyValue) => {
    let update = { ...contact };
    update[keyName] = keyValue;
    setContact(update);
  };

  const isOtpFormVisible = useSelector((state) => getIsOtpFormVisible(state));
  const loanleadDetails = useSelector((state) => fetchLoanLeadDetails(state));
  const isOtpVerify = useSelector((state) => getIsOtpVerify(state));

  const checkValid = (lead) => {
    let isValid = true;
    if (
      !lead.weddingloan.contact_name ||
      lead.weddingloan.contact_name === "" ||
      lead.weddingloan.contact_name.length < 3
    ) {
      isValid = false;
      showMessage("Please Enter First Name (min length 3)", "warning");
    } else if (
      !lead.weddingloan.contact_number ||
      lead.weddingloan.contact_number === "" ||
      lead.weddingloan.contact_number.length !== 10 ||
      !isNumeric(lead.weddingloan.contact_number)
    ) {
      isValid = false;
      showMessage("Enter a valid number (10 digits)", "warning");
    }
    return isValid;
  };

  const createLead = () => {
    const lead = {
      weddingloan: {
        contact_name: contact.contact_name,
        contact_number: contact.contact_number ? contact.contact_number.replace("+91",'').trim() : "",
        event_id: event.eventId,
        customer_id: user._id
      },
    };
    if (checkValid(lead)) {
      dispatch(createNewLead(lead));
    }
  };

  const handleChangeForm = (val) => {
    dispatch(isOtpScreenVisible(val));
  };

  const otpVerify = () => {
    dispatch(verifyOtp(loanleadDetails.weddingloan._id, otp));
  };

  const renderFormSlide = () => {
    return (
      <div>
        <div className={loanPageStyle["login-form-input"]}>
          <div>
            <span className={loanPageStyle["text"]}>Name</span>
            <div
              className={loanPageStyle["input-container"]}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <div className={loanPageStyle["icon-container"]}>
                <img
                  src={"https://vmnk.gumlet.io/pink-color-user-icon.png"}
                  style={{ width: "16px", height: "16px" }}
                />
              </div>
              <input
                placeholder="Enter Name"
                className={loanPageStyle["user-input"]}
                onChange={(e) => handleChange("contact_name", e.target.value)}
                value={contact.contact_name}
              />
            </div>
          </div>
          <div style={{ marginTop: "20px" }}>
            <span className={loanPageStyle["text"]}>Mobile</span>

            <div className={loanPageStyle["phone-input-container"]}>
              <PhoneInput
                country={"in"}
                value={contact.contact_number}
                onChange={(val) => handleChange("contact_number", val)}
                disableDropdown
                autoFormat
                placeholder="Enter Number"
                inputStyle={{
                  border: "2px solid pink",
                  width: "100%",
                  height: "44px",
                  borderRadius: "8px",
                  fontSize: "15px",
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              justifyContent: "center",
              marginTop: "20px",
              alignItems: "center",
            }}
          >
            <img
              src="https://cdn.venuemonk.com/whatsap_icon.svg"
              alt="whatsapp-image"
            />
            <span className={loanPageStyle["whatsapp-allow-text"]}>
              {SEND_WHATSAPP_TEXT}
            </span>
            <button
              className={`${loanPageStyle.toggleButton} ${
                isToggled
                  ? `${loanPageStyle.toggled} ${loanPageStyle.toggleButtonActivated}`
                  : ""
              }`}
              onClick={handleToggle}
            ></button>
          </div>
          <div
            className={loanPageStyle["button-container"]}
            style={{ justifyContent: "center", marginTop: "20px" }}
          >
            <button
              className={loanPageStyle["view-btn"]}
              onClick={() => createLead()}
            >
              <span className={loanPageStyle["View-button-text"]}>
                {NEXT_TEXT}
              </span>
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderOtpSlide = () => {
    return (
      <div style={{ padding: "20px" }}>
        <p className={loanPageStyle["otp-heading"]}>
          {MOBILE_VERIFICATION_TEXT}
        </p>
        <p
          className={loanPageStyle["text"]}
          style={{ textAlign: "center", marginTop: "20px" }}
        >
          A one time password(OTP) has been sent to your mobile number
          {contact.contact_number}. Please enter the OTP below.
        </p>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "30px",
          }}
        >
          <OtpInput
            onChange={(otp) => setOtp(otp)}
            value={otp}
            inputStyle={{
              width: "38px",
              height: "38px",
              border: "none",
              color: "black",
              backgroundColor: "white",
              border: "1px solid #FF477E",
              borderRadius: "6px",
            }}
            focusStyle={{
              border: "1px solid #FF477E",
            }}
            numInputs={4}
            shouldAutoFocus={true}
            separator={<span> &nbsp; &nbsp; </span>}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div
            className={loanPageStyle["button-container"]}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <button
              className={loanPageStyle["view-btn"]}
              style={{
                backgroundColor: "white",
                border: "1px solid rgba(255, 71, 126, 1)",
              }}
              onClick={() => handleChangeForm(false)}
            >
              <span
                className={loanPageStyle["View-button-text"]}
                style={{ color: "rgba(255, 71, 126, 1)" }}
              >
                Previous
              </span>
            </button>
          </div>
          <div
            className={loanPageStyle["button-container"]}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <button
              className={loanPageStyle["view-btn"]}
              onClick={() => otpVerify()}
            >
              <span className={loanPageStyle["View-button-text"]}>
                {VERIFY_TEXT}
              </span>
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderOtpVerifySlide = () => {
    return (
      <div style={{ padding: "40px" }}>
        <p className={loanPageStyle["otp-heading"]}>{OPT_VERIFY_TEXT}</p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <img
            src={`https://vmnk.gumlet.io/wp-verify-icon.png`}
            alt="verify-img"
            className={loanPageStyle["verify-img"]}
          />
        </div>
        <p
          className={loanPageStyle["text"]}
          style={{ textAlign: "center", marginTop: "20px" }}
        >
          {SUCCESSFUL_TEXT}
        </p>
        <p
          className={loanPageStyle["text"]}
          style={{ textAlign: "center", marginTop: "20px" }}
        >
          {VERIFY_OTP_SUBHEADING}
        </p>
      </div>
    );
  };

  return (
    <Loader loading={isFetchingUser}>
      <div className={loanPageStyle["form-wrapper"]}>
        <div className={loanPageStyle["form-header"]}>
          <span className={loanPageStyle["contact-us-heading"]}>
            {FORM_HEADER}
          </span>
        </div>
        {!isOtpVerify
          ? isOtpFormVisible
            ? renderOtpSlide()
            : renderFormSlide()
          : renderOtpVerifySlide()}
      </div>
    </Loader>
  );
}

export default LoanleadForm;
