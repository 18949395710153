import React from "react";
import "./Budget.css";
import Costgroup from "../../../components/images/costgroup.png";
import budgetcontent from "../../../constants/budget_content.json";

function Budget() {
  return (
    <div className="budget-main-container">
      {/*budget main container*/}
      <div className="budget-container">
        {/*budget sub container 1*/}
        {budgetcontent.budgets.map((budget) => (
          <div className="budget-sub-container-one">
            {/*bank-container*/}

            <div className="line"></div>
            <div className="bank-container">
              <div className="budget-alignment">
                <h2>
                  Elevate Your Wedding,Manage Your{" "}
                  <span style={{ color: "#FF477E" }}>Budget</span>
                </h2>
                <p>{budget.text_2}</p>
              </div>
            </div>
          </div>
        ))}

        {/*budget sub container 2*/}
        <div className="budget-sub-container-two">
          <img src={Costgroup} />

          {/*estimate cost-container*/}
          {/*}
          <div className='cost-container'>
             <div className='estimate-cost'><img src={Money}/><h3>Estimated Cost</h3>
             <p>₹ 10,00,000</p></div>
             <div className='lines'></div>

             <div className='estimate-cost'><img src={Piggy}/><h3>Final Cost</h3>
             <p>₹ 9,00,000</p>
            </div>
          </div> {/*estimate cost container end*/}

          {/*bridemakeup-container*/}
          {/*}  
        <div className='bridemakeup'>
          <h5>Bride’s Makeup Artist</h5>
           <div className='elpss'>
           <div>
           <p2>Paid: ₹ 40,000</p2><br/>
           <p2>Pending: ₹ 20,000</p2>
           <div className='line'>
               <div id="rectangle"></div><br/>
               <div id="rectangle-2"></div>
            </div>
           </div>
           <div><img src={Ellipses}/></div>
           </div>
        </div>  {/*bridemakeup container end*/}
        </div>
        {/*sub container 2 end*/}
      </div>
    </div>
  );
}

export default Budget;
