import fetch from 'isomorphic-fetch';

export const API_URL = process.env.REACT_APP_API_URL || 'https://api.weddingpe.com';

export default function callApi(endpoint, method = 'get', body, api_url) {
  let token = typeof window === 'undefined' ? '' : window.localStorage.getItem('token');
  let headers = {};
  headers['content-type'] = 'application/json';
  if (token && token !== '') {
    headers.Authorization = `Bearer ${token}`;
  }
  return fetch(`${api_url || API_URL}/${endpoint}`, {
    headers: headers,
    method,
    body: JSON.stringify(body),
    referrer: '',
    referrerPolicy: 'origin-when-cross-origin'
  }).then(response => response.json().then(json => ({ json, response })))
    .then(({ json, response }) => {
      if (!response.ok) {
        return Promise.reject(json);
      }
      return json;
    })
    .then(
      response => response,
      error => error
    );
}
