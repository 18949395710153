export const LOGIN_DETAIL_HEADING = "WeddingPe is a Wedding Insurance and Loan Platform that enables people to plan their weddign with financial assistance. Feel free to reach out to us."

export const LoginPageDetails = [
    {
        heading: 'Mail Us',
        subheading: 'info@weddingpe.com',
        icon: `https://vmnk.gumlet.io/message-icon.png`
    },
    {
        heading: 'Call Us',
        subheading: '+91-8446956120',
        icon: `https://vmnk.gumlet.io/call-icon.png`
    },
    {
        heading: 'Office Address',
        subheading: 'A 123, Sector 2, Greater Noida West',
        icon: `https://vmnk.gumlet.io/location-icon.png`
    },
]


export const Footericon = [
    {
        icon: `https://vmnk.gumlet.io/facebook-icon.png`
    },
    {
        icon: `https://vmnk.gumlet.io/instagram-icon.png`
    },
    {
        icon: `https://vmnk.gumlet.io/twitter-icon.png`
    },
]



export const WhyChooseSection = [
    {heading: "Low Interest Rates", desc: "Flexible and competitive rates tailored for weddings." },
    {heading: "Quick Approval", desc: "Get loan approval in as little as 24 hours." },
    {heading: "Easy Repayment", desc: "Simple repayment options that fit your budget." }, 
];


export const Loansteps = [
    {heading: "Apply Online", desc: "Complete our simple online application in just a few minutes." },
    {heading: "Get Approved", desc: "Complete our simple online application in just a few minutes." },
    {heading: "Receive Funds", desc: "Complete our simple online application in just a few minutes." }, 
    {heading: "Plan Your Wedding", desc: "Complete our simple online application in just a few minutes." }, 
];

export const APPLY_LOAN_APPLICATION_CTA = 'Apply for a Wedding Loan'

export const LOAN_BANNER_SUBHEADING = "Get easy and affordable wedding loans to make your big day stress-free!"

export const NEED_HELP_PHONE_TEXT = "Need help? Call us at +91-XXX-XXX-XXXX"

export const FORM_HEADER = "Fund Your Dream Wedding Easily"

export const SEND_WHATSAPP_TEXT = "Send me the updates on Whatsapp"

export const MOBILE_VERIFICATION_TEXT = "Mobile OTP verification"

export const OPT_VERIFY_TEXT = "OTP Verified!"

export const SUCCESSFUL_TEXT = "Verification Successful"

export const VERIFY_OTP_SUBHEADING ="Thank you! Your phone number has been verified. We’ll reach out to you soon with your loan eligibility details."

export const NEXT_TEXT = "Next"

export const VERIFY_TEXT = "Verify"