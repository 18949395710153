import React from "react";
import "./Hero_section.css";
import couple from "../../../components/images/couple.png";
import verify from "../../../components/images/verify.png";
import hero_content from "../../../constants/hero_content.json";
import { contactusPageURL, weddingpectabutton } from "../../../constants/path_constants";

function Hero_section() {
  return (
    <div className="main-wedding-container">
      <div className="wedding-container">
        {hero_content.home_section.map((homepage) => (
          <div className="wedding-sub-container-one row">
            <div className="firstheading">
              <img className="image" src={verify} />
              <h2 id="h2">{homepage.heading_text}</h2>
            </div>
            <div id="h1">
              <h1>
                Plan Your Perfect Day<span id="pink-line"></span>
                with WeddingPe
              </h1>
              <div id="wedding-content">
                <img src={couple} />
              </div>
            </div>
            <div className="row" id="para">
              <p4>{homepage.text}</p4>
            </div>
              <div className="btn-container">

              <a href={contactusPageURL} id="btn" className="cta-text shadow" type="submit">
                {homepage.button_text}
              </a>
            </div> 
          </div>
        ))}
        <div className="wedding-sub-container-two" id="wedding-couple">
          <img src={couple} />
        </div>
      </div>
    </div>
  );
}

export default Hero_section;
