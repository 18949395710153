import Hero_section from "./hero_section/Hero_section";
import Budgetsection from "./budgetsection/Budget";
import Blog from "../../components/blog/Blog";
import Testimonials from "./testimonial/Testimonials";
import Checklists from "./wedchecklists/Checklist";

const HomePage = () => {
  return (
    <div>
      <Hero_section />
      <Checklists />
      <Budgetsection />
      <Blog />
      <Testimonials />
    </div>
  );
};

export default HomePage;
