 import React from 'react'
 import loginformStyle from '../LoginPage.module.css'
import { LOGIN_DETAIL_HEADING, LoginPageDetails } from '../../../constants/Constant'
 
 function LoginDetail() {
   return (
     <div>
        <p className={loginformStyle['login-form-detail-heading']}>{LOGIN_DETAIL_HEADING}</p>
       
        {
          LoginPageDetails.map((item,index)=> (
            <div className={loginformStyle['detail-box']}key={index}>
            <img src={item.icon} alt='image' className={loginformStyle['login-page-icon']} />
            <div>
            <span className={loginformStyle['login-detail-text']}>{item.heading}</span><br/>
            <span className={loginformStyle['login-detail-sub-heading']}>{item.subheading}</span>
           </div>
           </div>
          ))}
       </div>
   )
 }
 
 export default LoginDetail
 