import fetch from 'isomorphic-fetch';
import moment from 'moment';

const API_URL = process.env.REACT_APP_EVENT_API_URL || `https://event-api.venuemonk.com`

export default function callEventApi(endpoint, method = 'get', body) {
  let token = typeof window === 'undefined' ? '' : window.localStorage.getItem('idToken');
    let headers = {};
    headers['content-type'] = 'application/json';
    if (token && token !=='') {
        headers.Authorization = `Bearer ${token}`;
    }
    return fetch(`${API_URL}/${endpoint}`, {
        headers: headers,
        method,
        body: JSON.stringify(body),
    })
        .then(response => response.json().then(json => ({json, response})))
        .then(({json, response}) => {
            if (!response.ok) {
                return Promise.reject(json);
            }
            return json;
        })
        .then(
            response => response,
            error => error
        );
}
