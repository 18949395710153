import React, { useEffect, useState } from "react";
import loanPageStyle from "./loan.module.css";
import LoanleadForm from "./LoanleadForm";
import {
  APPLY_LOAN_APPLICATION_CTA,
  LOAN_BANNER_SUBHEADING,
  Loansteps,
  NEED_HELP_PHONE_TEXT,
  WhyChooseSection,
} from "../../constants/Constant";
import { useSelector } from "react-redux";
import { getSelectedEvent, getUser, isFetching } from "../../Redux/Reducer/AppReducer";

function LoanLandingPage() {
  
  const [width, setWidth] = useState(window.innerWidth);
  const event = useSelector(getSelectedEvent);
  const user = useSelector(getUser)
  const isFetchingUser = useSelector(isFetching)


  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const cardstyle = width > 768 ? { gridTemplateColumns: '1fr 1fr 1fr 1fr' } : {};

  return (
    <div style={{}}>
      <div className={loanPageStyle["wrapper-container"]}>
        <div className={loanPageStyle["banner-container"]}>
          <div className={loanPageStyle["left-container"]}>
            <p className={loanPageStyle["heading"]}>
              Fund Your Dream Wedding with <span>WeddingPe</span>
            </p>
            <p className={loanPageStyle["subheading"]}>
              {LOAN_BANNER_SUBHEADING}
            </p>
            {width > 768 && 
            <>
            
            <div className={loanPageStyle["button-container"]}>
              <button onClick={() => {}} className={loanPageStyle["view-btn"]}>
                <span className={loanPageStyle["View-button-text"]}>
                  {APPLY_LOAN_APPLICATION_CTA}
                </span>
              </button>
            </div>
        <a href={`tel:${''}`} >
            <p
              className={loanPageStyle["subheading"]}
              style={{ marginTop: "40px" }}
            >
              {NEED_HELP_PHONE_TEXT}
            </p>
            </a>
            </>

}
          </div>
          <div className={loanPageStyle["cta-form-container"]}>
            <LoanleadForm user={user} event={event} isFetchingUser={isFetchingUser} width={width}/>
          </div>
        </div>
      </div>

      <div className={loanPageStyle["why-choose-section"]}>
        <p className={loanPageStyle["why-section-text"]}>
          Why Choose <span>WeddingPe</span>
        </p>
        <div className={loanPageStyle["card"]}>
          {WhyChooseSection.map((card, index) => (
            <div className={loanPageStyle["discover-container"]}>
              <div
                style={{
                  backgroundColor: "#FFAAC4",
                  width: "60px",
                  height: "60px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={`https://vmnk.gumlet.io/loan-card-image.png`}
                  className={loanPageStyle["loan-icon"]}
                  alt="image"
                />
              </div>
              <p className={loanPageStyle["discover-heading"]}>
                {card.heading}
              </p>
              <p className={loanPageStyle["discover-desc"]}>{card.desc}</p>
            </div>
          ))}
        </div>
      </div>

      <div className={loanPageStyle["steps-section"]}>
        <p className={loanPageStyle["why-section-text"]}>
          Get Your <span>Wedding Loan</span> in 4 Easy Steps
        </p>

        <div className={loanPageStyle["steps-section-card"]} >
          {Loansteps.map((card, index) => (
            <div className={loanPageStyle["steps-container"]}>
              <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
              <div
                style={{
                  backgroundColor: "#FFAAC4",
                  width: "60px",
                  height: "60px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  className={loanPageStyle["corporate-card-number-text"]}
                  style={{ margin: 0 }}
                >
                  {index + 1}
                </p>
              </div>
              </div>
              <p className={loanPageStyle["card-heading"]}>
                {card.heading}
              </p>
              <p className={loanPageStyle["card-desc"]}>{card.desc}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default LoanLandingPage;
