import {
  CHECK_DETAILS,
  FETCH_BUNDLE,
  FETCH_EVENT,
  FETCH_LEAD_DETAILS,
  FETCHING_BUNDLE,
  FETCHING_USER,
  IS_OTP_FORM_VISIBLE,
  IS_OTP_VERIFY,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  UPDATE_EVENT,
} from "../Action/AppAction";

// Initial State
const initialState = {
  check: false,
  loanlead: {},
  isOtpSent: false,
  isotpverify: false,
  isFetching: false,
  isAuthenticated: false,
  message: "",
  user: {},
  isFetching:false,
  isFetchingBundle: false,
  isFetchingEvent:false,
  isPlanningEvent:false,
  event:{},
  bundle:{},
  isSavingEventInfo:false
};

const AppReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHECK_DETAILS:
      return {
        ...state,
        check: action.check,
      };

    case FETCH_LEAD_DETAILS:
      return {
        ...state,
        loanlead: action.loanlead,
      };
    case FETCHING_USER:
        return{
            ...state,
            isFetching:action.isFetching
        }  

    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
        isFetching: action.isFetching,
        message: "",
        user: action.user,
      };

    case LOGIN_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        isFetching: false,
        message: action.message,
      };
    case FETCHING_BUNDLE:
      return {
        ...state,
        isFetchingBundle: action.isFetchingBundle,
      };
    case FETCH_EVENT:
      return {
        ...state,
        isFetchingEvent: action.isFetchingEvent,
        isPlanningEvent: action.isPlanningEvent,
        event: action.event,
      };
      case FETCH_BUNDLE:
      return {
        ...state,
        isFetchingBundle: action.isFetchingBundle,
        bundle: action.bundle,
      };
      case UPDATE_EVENT:
        return {
            ...state,
            isSavingEventInfo: action.isSavingEventInfo,
            event: action.event,
        };
    case IS_OTP_FORM_VISIBLE:
      return {
        ...state,
        isOtpSent: action.isOtpSent,
      };
    case IS_OTP_VERIFY:
      return {
        ...state,
        isotpverify: action.isotpverify,
      };
    default:
      return state;
  }
};
export const fetchLoanLeadDetails = (state) => state.app.loanlead;
export const getIsOtpFormVisible = (state) => state.app.isOtpSent;
export const getIsOtpVerify = (state) => state.app.isotpverify;
export const getSelectedEvent = (state) => state.app.event;
export const getUser = (state) => state.app.user;
export const isFetching = (state)=> state.app.isFetching

export default AppReducer;
