import React from "react";
import "./BlogListPage.css";
import "../../components/blog/Blog.css";
import { Link } from "react-router-dom";
import BlogPageStyle from "./BlogPage.module.css";
import author from "../../components/images/a-author.png";
import Badge from "../../components/images/badge.png";
import blog_content from "../../constants/blog_content.json";
import BlogCard from "../../components/blog/BlogCard";

function BlogListPage() {
  const BlogCategories = () => {
    return (
      <div className="mini-frame">
        <ul>
          <li className="mini-frame-items">
            <Link to="/Wedding">Accessories</Link>
          </li>
          <li className="mini-frame-items">
            <Link to="/Celebritypage">Birthday</Link>
          </li>
          <li className="mini-frame-items">
            <Link to="/Corporatepage">Baby Shower</Link>
          </li>
          <li className="mini-frame-items">
            <Link to="/Celebritypage">Celebrity</Link>
          </li>
          <li className="mini-frame-items">
            <Link to="/Corporatepage">Corporate Event</Link>
          </li>
          <li className="mini-frame-items">
            <Link to="/Celebritypage">Conference</Link>
          </li>
          <li className="mini-frame-items">
            <Link to="/blogs/2">Indian Wedding</Link>
          </li>
          <li className="mini-frame-items">
            <Link to="/Wedding">Wedding</Link>
          </li>
        </ul>
      </div>
    );
  };

  return (
    <>
      <div className="blog-main-container-two">
        <div className={BlogPageStyle['blog-container']} >
          <div className={BlogPageStyle['blog-heading']}>
            <h4>All Blogs</h4>
          </div>
          <div className={BlogPageStyle["card-deck1"]}>
            {blog_content?.blogs?.map((blogpage, index) => (
              <div className={BlogPageStyle["card-container"]}>
                <Link
                  className={BlogPageStyle['text-container']}
                  key={index}
                  to={`/blogs/${blogpage.url}`}
                >
                  <BlogCard
                    blogimage={blogpage.src}
                    title={blogpage.title}
                    authorimage={blogpage.author_img}
                    authortext={`${blogpage.author_name} ${" | "}${
                      blogpage.published_date
                    }`}
                    Badge={Badge}
                  />
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogListPage;
