export const homepageURL = "/";

export const blogListPageURL = "/blogs";

export const blogDetailsPageURL = "/blogs/:url";

export const testimonialsSectionURL ="/#testimonials";

export const contactusPageURL = "/contact";

export const loanPageURL = "/loan";

export const weddingpectabutton = 'https://docs.google.com/forms/d/1zmbVCVptFniIbP8rBNQZXKA92gqJrtLdEANrjyqs4-U/viewform?edit_requested=true'