
import React from 'react';
import styles from './Loader.module.css'; // Create a CSS module for the loader

const Loader = ({loading,children}) => {

  const getLoaderStyle = ()=>{
    if(!loading){
      return {
        background : "transparent"
      }
    }
  }


  return (
    <div className={styles.loaderOverlay} style={getLoaderStyle()} >
     {loading && <div className={styles.loader}></div>}
       {children}
    </div>
  );
};

export default Loader;