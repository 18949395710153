import React from "react";
import "./Navbar.css";
import { Link, useRoutes } from "react-router-dom";
import logo from "../images/logo.png";
import { blogListPageURL, contactusPageURL, loanPageURL, testimonialsSectionURL } from "../../constants/path_constants";

const Navbar = () => {



  
  return (
    <nav className="navbar-one">
      <Link to="./">
        <img className="wedding-logo" src={logo} />
      </Link>

      <ul className="nav-list">
        <li>
          <Link to={blogListPageURL} className="nav-link">
            Blog
          </Link>
        </li>
        {/* <li>
          <Link to={loanPageURL} className="nav-link">
            Loan
          </Link>
        </li> */}
        <li>
          <Link to={testimonialsSectionURL} className="nav-link ">
            Testimonials
          </Link>
        </li>
        <li>
          <Link to={contactusPageURL} className="nav-link ">
            Contact
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
