import React, { Suspense, useEffect, useRef } from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Router,
  Routes,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import {
  homepageURL,
  blogListPageURL,
  blogDetailsPageURL,
  contactusPageURL,
  loanPageURL,
} from "./constants/path_constants";

import "./App.css";
import Navbar from "./components/navbar/Navbar";
import HomePage from "./pages/home/HomePage";
import FullPageLoader from "./pages/loader/FullPageLoader";
import BlogListingPage from "./pages/blogpage/BlogListPage";
import BlogDetailsPage from "./pages/blog-details/BlogDetailsPage";
import Footer from "./components/footer/Footer";
import ContactUsWrapper from "./pages/ContactUsForm/ContactUsWrapper";
import LoanLandingPage from "./pages/loanPage/LoanLandingPage";
import { useDispatch, useSelector } from "react-redux";
import { consumeMagicToken } from "./Redux/Action/AppAction";
import { getSelectedEvent, getUser } from "./Redux/Reducer/AppReducer";

function App() {
  let [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const dispatch = useDispatch()
  const event = useSelector(getSelectedEvent);
  const user = useSelector(getUser)


  console.log(user,event,"conspsoso")

  const scrollToSection = (url) => {
    const hash = url.split("#")[1]; 
    const section = document.getElementById(hash);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

useEffect(()=>{
  let mgk = searchParams.get("mgk")
  if (
    mgk !== '' &&
    mgk !== 'undefined' &&
    mgk !== undefined
) {
    dispatch(consumeMagicToken(mgk));
}
},[])




  useEffect(() => {
    window.scrollTo(0, 0);
   
    if (location.hash) {
      scrollToSection(location.hash);
    }
  }, [location.hash]); 

  const NotAuthenticatedRoutes = () => {

    return (
      <Routes>
        <Route path={homepageURL} element={<HomePage />} />
        <Route path={loanPageURL} element={<LoanLandingPage />} />
        <Route path={blogListPageURL} element={<BlogListingPage />} />
        <Route path={contactusPageURL} element={<ContactUsWrapper />} />
        <Route path={blogDetailsPageURL} element={<BlogDetailsPage />} />
      </Routes>
    );
  };

  const getRoutes = () => {
    return <NotAuthenticatedRoutes />;
  };

  return (
    <>
        <Navbar 
        />
        <Suspense fallback={<FullPageLoader />}>{getRoutes()}</Suspense>
        <Footer />
    </>
  );
}

export default App;
