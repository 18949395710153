import React from "react";
import Slider from "react-slick";
import "./Testimonials.css";
import Star1 from "../../../components/images/Star1.png";
import Star5 from "../../../components/images/Star5.png";
import leftarrow from "../../../components/images/Arrowleft.png";
import rightarrow from "../../../components/images/Arrow.png";
import testimonial_content from "../../../constants/testimonial_content.json";
import { truncate } from 'lodash';
import { testimonialcontent } from "../../../constants/Testimonialconstant";
import NextArrow from "./NextArrow";
import PrevArrow from "./PrevArrow";

export default function Testimonials() {
  const settings = {
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    // infinite: true,
    // autoplay: true,
    // autoplaySpeed: 1000,
    arrows:true,
    nextArrow: <NextArrow styleClass='testimonial-next-arrow' image={rightarrow} />,
    prevArrow: <PrevArrow styleClass='testimonial-prev-arrow' image={leftarrow}/>,
    responsive: [
      {
        breakpoint: 430,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // infinite: true,
          arrows: true,
          //  arrows: false,
          nextArrow: <NextArrow styleClass='testimonial-next-arrow' image={rightarrow} />,
          prevArrow: <PrevArrow styleClass='testimonial-prev-arrow' image={leftarrow}/>,
        },
      },
    ],

  };

  const StarRating = ({ rating }) => {
    console.log(rating);
    return (
      <div className="star">
        <img src={rating > 0 ? Star1 : Star5} alt="star1" />
        <img src={rating > 1 ? Star1 : Star5} alt="star1" />
        <img src={rating > 2 ? Star1 : Star5} alt="star1" />
        <img src={rating > 3 ? Star1 : Star5} alt="star1" />
        <img src={rating > 4 ? Star1 : Star5} alt="star5" />
      </div>
    );
  };

  return (
    <>
      <div className="testimonial-main-container" id={"testimonials"}>
        <div className="testimonial-main_div">
          <h3 className="testimonial-heading" id="testimonial">
            Testimonial
          </h3>
        </div>
        <div className="testimonial-frame-div">
          <Slider {...settings}>
            {testimonialcontent.map((testimonial) => (
              <div className="testimonial-container">
                <frames className="testimonial-frame">
                  <img
                    className="face"
                    src={testimonial.src}
                    height={28}
                    width={28}
                    alt="img3"
                  />

                  <StarRating rating={testimonial.rating} />

                  <h3>{testimonial.name}</h3>

                  <p>
                    {testimonial.text}
                  </p>
                </frames>
              </div>
            ))}
          </Slider>
          {/* <div>
            <button className="btn btn-box" id="review-btn">
              <span className="review-button-text">Write a Review</span>
            </button>
          </div> */}
        </div>
      </div>
    </>
  );
}
