import React from 'react'
import ContactUsPage from './ContactUsPage';

function Login() {
    return (
        <div>       
            <ContactUsPage />
        </div>
    );
}

export default Login
