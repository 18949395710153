// import React from 'react';
// import lowerCase from 'lodash/lowerCase';
// import {toast} from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// export function userNotify(msg, msgtype) {
//     let notificationOpts = {
//         message: msg,
//         position: 'tc',
//         autoDismiss: 2,
//         level: msgtype,
//     };

//     let shortlistNotificationOpts = {
//         message: msg,
//         position: 'tc',
//         autoDismiss: 3,
//         level: msgtype,
//     };


//     switch (lowerCase(msgtype)) {

//         case 'warning':
//             toast.error(msg, {
//                 position: toast.POSITION.TOP_RIGHT
//             });
//             break;

//         case 'danger':
//             toast.error(msg, {
//                 position: toast.POSITION.TOP_RIGHT,
//                 className: 'toast-message-danger'
//             });
//             break;

//         case 'error':
//             toast.error(msg, {
//                 position: toast.POSITION.TOP_RIGHT
//             });
//             break;

//         case 'info':
//             toast.error(msg, {
//                 position: toast.POSITION.TOP_RIGHT
//             });
//             break;
//         case 'success':
//             toast.success(msg, {
//                 position: toast.POSITION.TOP_RIGHT
//             });
//             break;

//         case 'shortlist':
//             toast.success(msg, {
//                 position: toast.POSITION.TOP_RIGHT
//             });
//             break;
//         default:
//             toast.error(msg, {
//                 position: toast.POSITION.TOP_RIGHT
//             });
//     }
// }

// //   switch (lowerCase(msgtype)) {
// //     case 'warning':
// //       return warning(notificationOpts);

// //     case 'danger':
// //       return error(notificationOpts);

// //     case 'error':
// //       return error(notificationOpts);

// //     case 'info':
// //       return info(notificationOpts);

// //     case 'success':
// //       return success(notificationOpts);

// //     case 'shortlist':
// //       return info(shortlistNotificationOpts);

// //     default:
// //       return show(notificationOpts);
// //   }
// // }



// Import React Toastify
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Initialize Toastify
toast.configure();

// Function to show a toast
export const showMessage = (msg, type) => {
    switch (type) {
        case 'success':
            toast.success(msg);
            break;
        case 'error':
            toast.error(msg);
            break;
        case 'info':
            toast.info(msg);
            break;
        case 'warning':
            toast.warn(msg);
            break;
        default:
            toast(msg);
            break;
    }
};
