import { useState, useEffect } from "react";
import React from "react";
import Slider from "react-slick";
import Badge from "../images/badge.png";
import blog_content from "../../constants/blog_content.json";
import author from "../images/a-author.png";
import BlogCard from "./BlogCard";
import Blogstyle from "./BlogCard.module.css";
import { Link } from "react-router-dom";
import { blogListPageURL } from "../../constants/path_constants";

function Blog() {
  const [pathName, setPathName] = useState(window.location.pathName);
  useEffect(() => {
    const pathName = window.location.pathname;
    setPathName(pathName);
  }, []);

  const settings = {
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 430,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          // arrows: true,
        },
      },
    ],
  };

  return (
    <div>
      <div className={Blogstyle["blog-container"]}>
        <div className={Blogstyle["blog-heading"]}>
          <h1>Blog</h1>
        </div>
        <div>
          <div className={Blogstyle["blog-frame"]}>
            <Slider {...settings}>
              {blog_content?.blogs?.map((blogpage, index) => (
                <div className={Blogstyle["blogg"]}>
                       <Link
                  className={Blogstyle['text-container']}
                  key={index}
                  to={`/blogs/${blogpage.url}`}
                >
                  <BlogCard
                    blogimage={blogpage.src}
                    title={blogpage.title}
                    authorimage={blogpage.author_img}
                    authortext={`${blogpage.author_name} ${" | "}${
                      blogpage.published_date
                    }`}
                    Badge={Badge}
                  />
                </Link>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <div>
        <Link style={{"textDecoration":"none"}} to={blogListPageURL} >
        <button  className={Blogstyle["view-btn"]}>
          <span className={Blogstyle["View-button-text"]}>View All</span>
        </button>
        </Link>
      </div>
    </div>
  );
}
export default Blog;
