import { showMessage } from "../../components/Toast/ToastAdd";
import callApi from "../apiCaller";
import callEventApi from "../eventApiCaller";

export const CHECK_DETAILS = "CHECK_DETAILS";
export const FETCH_LEAD_DETAILS = "FETCH_LEAD_DETAILS";
export const IS_OTP_FORM_VISIBLE = "IS_OTP_FORM_VISIBLE";
export const IS_OTP_VERIFY = "IS_OTP_VERIFY";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const FETCHING_BUNDLE = "FETCHING_BUNDLE"; 
export const UPDATE_EVENT = "UPDATE_EVENT"; 
export const FETCH_BUNDLE = "FETCH_BUNDLE"; 
export const FETCH_EVENT = "FETCH_EVENT"; 
export const FETCHING_USER = "FETCHING_USER"; 

export function loadleadDetails(data) {
  return {
    type: FETCH_LEAD_DETAILS,
    loanlead: data,
  };
}

export function isOtpScreenVisible(status) {
  return {
    type: IS_OTP_FORM_VISIBLE,
    isOtpSent: status,
  };
}

export function isOtpVerify(status) {
  return {
    type: IS_OTP_VERIFY,
    isotpverify: status,
  };
}

export function createNewLead(lead) {
  return async (dispatch) => {
    const res = await callEventApi(
      `api/v1/weddingloans/new-enquiry`,
      "post",
      lead
    );
    if (res.status === "Success") {
      dispatch(loadleadDetails(res.data));
      localStorage.setItem("loanleadDetails", JSON.stringify(res.data));
      if (res.data) {
        dispatch(
          sendVerificationOtp(
            res.data.weddingloan._id,
            res.data.weddingloan.contact_number
          )
        );
        dispatch(isOtpScreenVisible(true));
      }
      showMessage("Lead Created", "success");
    } else {
      showMessage("Lead Creation Failed", "error");
    }
  };
}

export function sendVerificationOtp(id, number, code) {
  return async (dispatch) => {
    try {
      const payload = {
        phone: number,
      };
      const res = await callEventApi(
        `api/v1/weddingloans/${id}/send-verify-otp`,
        "post",
        payload
      );

      if (res.status === "Success") {
        showMessage("OTP Sent Successfully", "success");
      } else {
        showMessage("OTP Sending Failed", "error");
      }
    } catch (error) {
      showMessage("error");
    }
  };
}

export function consumeMagicToken(mtoken, router) {
  return (dispatch) => {
    dispatch(showFetchingStage())
    return callApi(
      `auth/consume-magic-token?mtoken=${mtoken}`,
      "post",
      {}
    ).then((res) => {
      const { user, message } = res;
      if (user) {
        if (!user.ok) {
          dispatch(loginFailure(message));
        } else {
          localStorage.setItem("token", user.token);
          localStorage.setItem("idToken", user.idToken);
          const event = user.event;

          dispatch(fetchEventAndBundle(event._id));
          dispatch(loginSuccess(user));
        }
      } else {
        dispatch(loginFailure(message));
        showMessage(message, "danger");
      }
    });
  };
}

export function loginSuccess(user) {
  return {
    type: LOGIN_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    token: user.token,
    user: user.data,
  };
}


export function loginFailure(message) {
  return {
      type: LOGIN_FAILURE,
      isFetching: false,
      isAuthenticated: false,
      message,
  };
}


export function showFetchingStage(){
  return {
    type: FETCHING_USER,
    isFetching: true,
};
}

export function fetchEventAndBundle(id) {
  return (dispatch) => {
      return callEventApi(`api/customer/dashboard/user/${id}/customer-event`, 'GET').then((res) => {
          if (res.status === 'Success') {
              dispatch(loadEvent(res.data.event));
              if (res.data && res.data.event && res.data.event.bundleId) {
                  dispatch(fetchEventPopulatedBundle(res.data.event.bundleId));
                  dispatch(savedEventInfo(res.data.event));
              }
          } else {
              // showMessage('Failed to load event info, please try again', 'error');
          }
      });
  };
}


export function fetchEventPopulatedBundle(id) {
  return (dispatch) => {
      dispatch(fetchingEventBundle());
      return callEventApi(`api/customer/dashboard/user/bundle/${id}/populated`, 'get').then((res) => {
          if (res.status === 'Success') {
              dispatch(loadBundle(res.data.bundle));
          }
      });
  };
}


export function fetchingEventBundle() {
  return {
      type: FETCHING_BUNDLE,
      isFetchingBundle: true,
  };
}

export function loadEvent(event, isPlanningEvent) {
  return {
      type: FETCH_EVENT,
      isFetchingEvent: false,
      isPlanningEvent: !!isPlanningEvent,
      event: event,
  };
}

export function loadBundle(bundle) {
  return {
      type: FETCH_BUNDLE,
      isFetchingBundle: false,
      bundle: bundle,
  };
}

export function savedEventInfo(event) {
  return {
      type: UPDATE_EVENT,
      isSavingEventInfo: false,
      event: event,
  };
}


export function verifyOtp(id, code) {
  return async (dispatch) => {
    try {
      const payload = {
        code: code,
      };
      const res = await callEventApi(
        `api/v1/weddingloans/${id}/verify-otp`,
        "post",
        payload
      );

      if (res.status === "Success") {
        showMessage("OTP Verify Successfully", "success");
        dispatch(isOtpVerify(true));
      } else {
        showMessage("Invalid OTP", "error");
      }
    } catch (error) {
      showMessage("error");
    }
  };
}
